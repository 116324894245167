import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: #000;
    overflow: hidden;

    border-radius: 1rem;

    box-shadow: ${theme.layout.shadows.popup};

    width: 1080px;

    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      object-fit: cover;
    }

    footer {
      display: flex;
      justify-content: center;
      padding: ${theme.layout.spacing(2)};
    }
  `}
`;
